import React, { Fragment } from 'react'
import useMediaQuery from '../../../../hooks/useMediaQuery'
import { CASES } from '../../../../data/cases'
import { IMG_PATH } from '../../../../constants/paths'

const CareerCases = () => {
    const pc = useMediaQuery()
    return (
        <div className="career-cases">
            <div className='section-content'>
                <div className="career-cases__cases">
                    {CASES.map((c, i) => <Fragment key={c.title}>
                        <CareerCase cCase={c} />
                        {i < CASES.length - 1 &&
                            <picture style={{ display: 'inline-block' }}>
                                <source srcSet={IMG_PATH + `design/divider-hor.png`} media="(max-width: 899px)" />
                                <source srcSet={IMG_PATH + `design/divider-vert.png`} media="(min-width: 900px)" />
                                <img className='career-cases__divider' src={IMG_PATH + `design/divider-hor.png`} />
                            </picture>
                        }
                    </Fragment>)}
                </div>
            </div>
        </div>
    )
}

const CareerCase = ({ cCase }) => {
    const { points, title, note } = cCase
    return (
        <div className="career-cases__case">
            <h4>{title}</h4>
            <ul>
                {points.map((p, i) => <li key={i}>{p}</li>)}
            </ul>
            {note && <p className={'career-cases__note'}>{note}</p>}
        </div>
    )
}

export default CareerCases