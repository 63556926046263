import React from 'react'
import CareerPoint from './CareerPoint'
import CareerCases from './CareerCases'
import Testimonials from '../Testimonials'
import { CAREER_TESTIMONIALS } from '../../../../data/testimonials'

const Career = () => {
    return (
        <section>
            <CareerPoint />
            <CareerCases />
            <Testimonials data={CAREER_TESTIMONIALS} />
        </section>
    )
}

export default Career