import React from 'react'
import { Link } from 'react-router-dom';

const ConditionalLink = ({ url, href, text, blank = true, children, log, className = '' }) => {

    var realURL = url ?? href;
    var openBlank = blank
    var samePage = false

    function isValidUrl(string) {
        try {
            new URL(string);
            return true;
        } catch (err) {
            return false;
        }
    }


    if (realURL && realURL !== undefined) {
        if (isValidUrl(realURL)) {

            const nurl = new URL(realURL)
            if (nurl.origin.toString() === window.location.origin.toString()) {
                realURL = nurl.pathname
                openBlank = false
                samePage = true
            }


        } else {
            openBlank = false
            samePage = true
        }

    }

    const content = text ?? children

    return (
        <>
            {samePage ? <Link className={className} to={realURL}>{content}</Link>
                : realURL ? <a className={className} href={realURL} target={openBlank ? '_blank' : undefined} rel={openBlank ? 'noopener noreferrer' : undefined}>{content}</a>
                    : <span className={className}>{content}</span>}
        </>
    )
}

export default ConditionalLink