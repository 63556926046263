import React from 'react'
import ConditionalLink from '../ConditionalLink'
import { IMG_PATH } from '../../../constants/paths'



const NavButton = ({ text, url, bottom }) => {


    return (
        <ConditionalLink url={url} className={`nav-btn ${bottom ? 'bottom' : 'main'}`} >
            <div className="nav-btn__content">
                <span style={{ textDecoration: 'none' }}>{text}</span>
                <i className="icon-nav-arrow" />
            </div>
        </ConditionalLink>
    )
}

export default NavButton