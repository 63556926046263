import React from 'react'
import NavButton from '../../ui/buttons/NavButton'
import SiteButton from '../../ui/buttons/SiteButton'
import { FORM_URL } from '../../../constants/paths'

const GuidelinesFooter = () => {
    return (
        <section className='guidelines-footer'>
            <div className="guidelines-footer__nav-button">
                <NavButton text='応募フォームへ' url={FORM_URL} />

            </div>
            <SiteButton />
        </section>
    )
}

export default GuidelinesFooter