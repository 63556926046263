import React from 'react'
import useScrollOffset from '../../../hooks/useScrollOffset'

const ScrollToTopButton = ({ visible, className }) => {
    const scrollOffset = useScrollOffset()

    const handleScroll = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' })
    }
    return (
        <button onClick={handleScroll} className={`button-top${scrollOffset > 500 ? ' visible' : ' hidden'}${className ? ' ' + className : ''}`}>
            <span className="material-symbols-outlined">
                arrow_upward
            </span>
        </button>
    )
}

export default ScrollToTopButton