const LOCATIONS = [
  {
    name: '梅田',
    imgName: 'umeda',
  },
  {
    name: '西宮北口',
    imgName: 'nishinomiya',
  },
  {
    name: '仁川',
    imgName: 'nigawa',
  },
  {
    name: '逆瀬川',
    imgName: 'sakase',
  },
  {
    name: '三田',
    imgName: 'sanda',
  },
  {
    name: '川西',
    imgName: 'kawanishi',
  },
];

export default LOCATIONS