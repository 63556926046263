import React from 'react'

const FloatingDesc = ({ catchTxt, txt }) => {
  return (
    <div className='floating-desc'>
      {catchTxt && <p className="floating-desc__catch-txt">{catchTxt}</p>}
      <p className="floating-desc__main-txt">{txt}</p>
    </div>
  )
}

export default FloatingDesc