import React from 'react'
import useMediaQuery from '../../../hooks/useMediaQuery'

const GuidelinesTable = ({ pos, hidden }) => {


    return (
        <section className={`guidelines-tbl${hidden ? ' hidden' : ''} ${pos.id}`}>
            <table >
                <tbody>
                    {pos.details.map((d, i) => <TableRow key={i} item={d} />)}
                </tbody>
                <tfoot>
                    <tr>
                        <td colSpan={2}>
                            {pos.foot}
                        </td>
                    </tr>

                </tfoot>
            </table >
        </section>

    )
}


const TableRow = ({ item }) => {
    const pc = useMediaQuery()
    var name = item.name
    if (!pc) {
        if (name === 'その他特徴') {
            name = <>その他<br />特徴</>
        } else if (name === '必要資格・経験') {
            name = <>必要資格・<br />経験</>
        }

    }
    return (
        <tr>
            <th>{name}</th>
            <td>{item.desc}</td>
        </tr>
    )
}

export default GuidelinesTable