import React, { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'

import BasicButton from '../components/ui/buttons/BasicButton'
import ContentWrapper from '../components/design/ContentWrapper'
import GuidelinesFooter from '../components/sections/guidelines/GuidelinesFooter'
import GuidelinesTable from '../components/sections/guidelines/GuidelinesTable'
import ApplicationProcess from '../components/sections/guidelines/ApplicationProcess'
import { POSITION_DETAILS } from '../constants/jobConditions'

const GuidelinesScreen = () => {

    const [searchParams, setSearchParams] = useSearchParams();
    const [pos, setPos] = useState(POSITION_DETAILS.stylist)

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    useEffect(() => {
        if (searchParams.get('position')) {
            if (POSITION_DETAILS[searchParams.get('position')]) {
                setPos(POSITION_DETAILS[searchParams.get('position')])
            } else {
                setPos(POSITION_DETAILS.stylist)
                setSearchParams({})
            }
        }
    }, [searchParams])

    const selectorButton = (p) => <BasicButton txt={`${p.name}募集`} txtSP={p.nameSP} onClick={() => { setSearchParams({ position: p.id }); setPos(p) }} disabled={pos.id === p.id} />

    return (
        <>
            <ContentWrapper guidelines >
                <div className="guidelines">
                    <section className="guidelines__header">
                        <h1>募集要項</h1>
                        <div className="guidelines__selector">
                            {selectorButton(POSITION_DETAILS.stylist)}
                            {selectorButton(POSITION_DETAILS.assistant)}
                        </div>
                    </section>
                    <section className="guidelines__table-head">
                        <h2>{pos.name}募集</h2>
                        {/* <p>西宮ガーデンズ店オープニングスタッフ他、全店で募集中！</p> */}
                    </section>


                    <GuidelinesTable pos={pos} />
                    {/* <GuidelinesTable pos={POSITION_DETAILS.assistant} hidden={pos.id !== POSITION_DETAILS.assistant.id} /> */}

                    <ApplicationProcess />
                    <GuidelinesFooter />
                </div>

            </ContentWrapper>

        </>
    )
}

export default GuidelinesScreen