import React, { useState } from 'react'
import ReactPlayer from 'react-player'
import ResponsiveImg from '../../ui/ResponsiveImg'
import useMediaQuery from '../../../hooks/useMediaQuery'

import LeafImage from '../../design/LeafImage'

import { IMG_PATH, VDO_PATH } from '../../../constants/paths'


const Hero = () => {
    const pc = useMediaQuery()
    const [playing, setPlaying] = useState(null)
    // const catchTxt = <p className='floating-desc__catch-txt hero__catch-txt'>新人のあなたも、キャリアのあなたも、<br />美容師という仕事に「人」として向き合えば、<br />「なりたい自分」と出会えます。</p>
    const catchTxt = `新人のあなたも、キャリアのあなたも、\n美容師という仕事に「人」として向き合えば、\n「なりたい自分」と出会えます。`
    const onPlay = () => {
        setPlaying(true)

    }

    return (
        <header className='hero'>


            <LeafImage side='left' num={0} style={{ top: 0, width: '12.2rem' }} />
            <div className="hero__vdo-container">
                {/* <video muted="true" preload="auto" autoPlay="true" loop="true" playsInline="true" style={{ width: '100%', height: '100%' }} src={`${VDO_PATH}hero_${pc ? 'pc' : 'sp'}.mp4`} >
                    <source src={`${VDO_PATH}hero_pc.mp4`} type="video/mp4" />
                    <source src={`${VDO_PATH}hero_sp.mp4`} media="(max-width: 899px)" type="video/mp4" />
                    Could not play video
                </video> */}
                <ReactPlayer
                    style={{ opacity: playing ? 1 : 0 }}
                    url={`${VDO_PATH}hero_${pc ? 'pc' : 'sp'}.mp4`}
                    onPlay={onPlay}
                    playing
                    loop
                    fallback={<p>Video loading....</p>}
                    muted
                    width="100%"
                    height="100%"
                    config={{ file: { attributes: { playsInline: true, }, }, }}
                />
                <ResponsiveImg className={`hero__vid-img${playing ? ' hidden' : ''}`} src='photos/hero-img' varRes={false} type='jpg' />
                <div className="hero__vdo-tint" />
                <img className='hero__logo' src={IMG_PATH + 'logo.png'} alt="" />

            </div>
            {!pc && <div className="hero__catch">
                <p className='hero__catch-mbl'>{catchTxt}</p>
            </div>}

            <div className='floating-desc'>
                <p className="floating-desc__catch-txt floating-desc__catch-txt--pc">{catchTxt}</p>
                <p className="floating-desc__main-txt floating-desc__main-txt--sp">{`一緒に働く人にもお客様にも、\n思いやりを持って真心を込めて向き合う。\n人と向き合えば\n「仕事」や「技術」にも向き合える。\n様々な思いを抱えた\n「すべての人」に思いやりを。\nそれが私たちが一番大切にしていることです。\n「すべての人」の中に「あなた」もいます。\n「あなた」はどんな場所で\nどんな人になりたいですか？`}</p>
                <p className="floating-desc__main-txt floating-desc__main-txt--pc">{`一緒に働く人にもお客様にも、\n思いやりを持って真心を込めて向き合う。\n人と向き合えば「仕事」や「技術」にも向き合える。\n様々な思いを抱えた「すべての人」に思いやりを。\nそれが私たちが一番大切にしていることです。\n「すべての人」の中に「あなた」もいます。\n「あなた」はどんな場所でどんな人になりたいですか？`}</p>
            </div>

            {/* <FloatingDesc catchTxt={pc && catchTxt} txt={`一緒に働く人にもお客様にも、\n思いやりを持って真心を込めて向き合う。\n人と向き合えば${pc ? '' : `\n`}「仕事」や「技術」にも向き合える。\n様々な思いを抱えた${pc ? '' : `\n`}「すべての人」に思いやりを。\nそれが私たちが一番大切にしていることです。\n「すべての人」の中に「あなた」もいます。\n「あなた」はどんな場所で${pc ? '' : `\n`}どんな人になりたいですか？`} /> */}

        </header >
    )
}

export default Hero