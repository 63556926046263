import React from 'react'
import { COLOR } from '../../../constants/colors'

const BasicButton = ({ txt, txtSP, onClick, disabled, textColor = COLOR.WHITE.color }) => {
    return (
        <button style={{ color: textColor }} className='btn btn-basic' onClick={onClick} disabled={disabled}>
            <span className={'pc-only'}>{txt}</span>
            <span className={'sp-only'}>{txtSP ?? txt}</span>
        </button>
    )
}

export default BasicButton