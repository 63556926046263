import React from 'react'
import useMediaQuery from '../../../hooks/useMediaQuery'
import CustomSwiper from '../../ui/CustomSwiper'
import LocationCard from '../../design/LocationCard'
import { IMG_PATH } from '../../../constants/paths'
import LOCATIONS from '../../../data/locations'





const Opening = () => {
    const pc = useMediaQuery()
    const carouselImgs = ['location/newshop1.jpg', 'location/newshop2.jpg']

    return (
        <section >
            <div className="section-content opening">
                {/* <img src={IMG_PATH + `typography/newopen${pc ? '_pc' : ''}.png`} alt='今秋 NEW OPEN!' className="opening__heading" />
                <p className='opening__subtitle'>西宮ガーデンズ新館に新店舗オープン！</p>
                <img src={IMG_PATH + 'typography/open_bubble.png'} alt='オープニングスタッフ大募集' className="opening__bubble" />

                {pc ?
                    <div className='opening__newloc-imgs'>
                        {carouselImgs.map(s => <img key={s} src={IMG_PATH + s} />)}
                    </div> :
                    <div className="opening__swiper">
                        <CustomSwiper images={carouselImgs} className='opening__swiper-img' />
                    </div>
                } */}

                <p className='opening__subtitle'>SARAJU全店舗でスタッフ募集中！</p>
                <div className="opening__locations">
                    {LOCATIONS.map((l, k) => <LocationCard location={l} key={k} />)}
                </div>


            </div>
        </section >
    )
}

export default Opening