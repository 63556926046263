import React from 'react'
import { MAIN_SITE } from '../../../constants/paths'

const SiteButton = () => {
    return (
        <a className='btn btn-site' href={MAIN_SITE} target='_blank' rel='noopener noreferrer' >
            <div className="btn-site__content">
                <i className="icon-flower" />
                <span>SARAJUサイトはこちら</span>
            </div>
        </a>
    )
}

export default SiteButton