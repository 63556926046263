import React from 'react'
import useMediaQuery from '../../hooks/useMediaQuery'

import LeafImage from './LeafImage'

import { IMG_PATH } from '../../constants/paths'


const ContentWrapper = ({ children, home }) => {
    const pc = useMediaQuery()
    const media = pc ? 'pc' : 'sp';

    return (
        <main className='content-wrapper'>

            <div className="content-wrapper__top">
                <div className="content-wrapper__upper-crust">
                    {!pc && home && <div className='content-wrapper__sp-spacer' />}
                    <img className='yellow-bg yellow-bg--top' src={IMG_PATH + 'design/yellow-bg-top_' + media + '.svg'} />
                </div>
                <LeafImage side='left' num={1} style={{ top: pc ? '-2rem' : 0, width: pc ? '15rem' : '7.5rem' }} />
                <LeafImage side='left' num={2} style={{ top: pc ? '23.3rem' : home ? `17rem` : '26.5rem', width: pc ? '23.8rem' : '11.9rem' }} />
                <LeafImage side='left' num={3} style={{ top: pc ? '75rem' : '68.5rem', width: pc ? '19.4rem' : '9.7rem' }} />
                <LeafImage side='right' num={4} style={{ top: pc ? '5rem' : home ? '25.5rem' : '5.5rem', width: pc ? '21rem' : '10.5rem' }} />
                <LeafImage side='right' num={5} style={{ top: pc ? '68rem' : '96rem', width: pc ? '17rem' : '8.5rem' }} />
            </div>
            {children}
            <div className='content-wrapper__leaf-bottom'>
                <LeafImage side='left' num={6} style={{ top: 0, width: pc ? '14.2rem' : '7.1rem' }} />
                <LeafImage side='left' num={7} style={{ bottom: 0, width: pc ? '14.8rem' : '7.4rem' }} />
                <LeafImage side='right' num={8} style={{ top: 0, width: pc ? '19rem' : '9.5rem' }} />
                <LeafImage side='right' num={9} style={{ bottom: 0, width: pc ? '14.2rem' : '7.1rem' }} />
            </div>


            <img className='yellow-bg yellow-bg--bottom' src={IMG_PATH + 'design/yellow-bg-bottom_' + media + '.svg'} />

        </main>
    )
}






export default ContentWrapper