export const TRAINING_SCHEDULE_ITEMS = [
  {
    period: '１ヶ月〜６ヶ月',
    title: 'アシスタント期',
    points: [
      '入客する為の課題に取り組む',
      'カラーリスト(自己集客)に向けての準備期間',
    ],
  },
  {
    period: '７ヶ月〜12ヶ月',
    title: 'カラーリスト期',
    points: [
      '営業中にカラーリストとして自分の顧客を集客',
      'カラーのカウンセリングも可能',
    ],
  },
  {
    period: '13ヶ月〜18ヶ月',
    title: 'カラーリスト/Jrスタイリスト期',
    points: [
      '営業内にカットを含めた全技術で自己集客が可能',
      'スタイリストテビューに向けて実践感覚を養う期間',
    ],
  },
  {
    period: '19ヶ月〜24ヶ月',
    title: 'Jrスタイリスト期',
    points: [
      'Jrスタイリストとして営業でのお客様入客スタート',
      'リピート率・客単価・指名率UPに取り組む期間',
    ],
  },
];

export const TRAINING_POINTS = [
  '勤務時間内にレッスンが受けれるので、プライベートや自分がやりたい技術を学べる時間が確保できます!',
  'モデルレッスンを中心とした実践型のレッスン体制',
  '各店の責任者、スタイリストたちがマンツーマンに近いかたちでレッスンをサポート',
  '人間として成長できる心の育成',
];
