import React from 'react'

import useMediaQuery from '../../../hooks/useMediaQuery'

import { IMG_PATH } from '../../../constants/paths'




const TopPoints = () => {
    const pc = useMediaQuery()

    const pointTxt = ['スキルに見合う評価位最高水準還元率', 'ライブステージで選べる働き方', '最短２年でスタイリストデビューが可能！', '人を幸せにできる心の育成']

    return (
        <section >
            <div className="section-content top-points">
                <img className='top-points__accessory' src={IMG_PATH + 'typography/good.png'} alt="" />
                <h2>SARAJUで「なりたい自分」に{!pc && <br />}出会えるポイント！</h2>
                <div className="top-points__point-grid">
                    {Array(4).fill().map((_, i) => <img alt={pointTxt[i]} className='top-points__point' key={i} src={IMG_PATH + `points/point${i + 1}.png`} />)}
                </div>
            </div>
        </section>
    )
}

export default TopPoints