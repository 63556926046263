import React from 'react'

import useMediaQuery from '../../../hooks/useMediaQuery'

import ResponsiveImg from '../../ui/ResponsiveImg'
import FloatingDesc from '../../design/FloatingDesc'

import { IMG_PATH } from '../../../constants/paths'

const Credo = () => {
    const pc = useMediaQuery()
    return (
        <div className='credo'>

            <div className="credo__top">

                <div className="credo__bkg-chevron" />
                <div className="credo__top-float">
                    <ResponsiveImg src='photos/credo-bg' type='jpg' alt='credo photo' className='credo__bkg-photo' />
                    <FloatingDesc txt={`学びに終わりはありません。\n「人」として成長できる「あなた」なら、「美容師」としても必ず成長できます。\nそして、そんな「あなた」となら${pc ? '' : `\n`}私たちもまた成長できると思うのです。\nここには「人」として「美容師」として成長できる場所があります。`} catchTxt={`スタッフを思いやる心は${pc ? '' : `\n`}お客様を思いやる心。`} />
                </div>
            </div>
            <div className='credo__bottom'>
                <h3 className='img-header'><span>私たちの信条に共感し</span>{!pc && <br />}<span>チームメイトになってくれる{`\n`}</span>
                    <span className='img-header__flex-txt'>すべての「<img src={IMG_PATH + 'typography/anata.png'} className='credo__txt-img' />」を待っています。</span></h3>

                <div className="credo__people">
                    {Array(4).fill().map((_, i) => <ResponsiveImg key={i} src={`people/${i + 1}`} varImg={false} type='png' alt="" className="credo__person" />)}
                </div>

            </div>
        </div>
    )
}

export default Credo