import { useEffect, useCallback, useState } from 'react'


import { useLocation } from 'react-router-dom';




const useGetLocation = (includeSubdirectories) => {
    const location = useLocation()

    const [currentLocation, setCurrentLocation] = useState('')

    const resetSelectedNav = useCallback(() => {
        var path = location.pathname
        if (path.charAt(0) === '/')
            path = path.slice(1);
        if (!includeSubdirectories) {
            const pathComponents = path.split('/')
            path = pathComponents.length > 0 ? pathComponents[0] : ''
        }

        setCurrentLocation(path)

    })

    useEffect(() => {

        resetSelectedNav()
    }, [location])



    return currentLocation


}

export default useGetLocation