import React, { useState, useEffect } from 'react'

const useScrollOffset = () => {

    const [scrollOffset, setScrollOffset] = useState(null)

    useEffect(() => {

        const scrollHandler = () => {
            setScrollOffset(window.pageYOffset)
        }
        window.addEventListener('scroll', scrollHandler)

        return () => { window.removeEventListener('scroll', scrollHandler) }
    }, [])

    return scrollOffset;
}

export default useScrollOffset