import React, { useEffect } from 'react'
import useMediaQuery from '../hooks/useMediaQuery'

import Hero from '../components/sections/home/Hero'
import TopPoints from '../components/sections/home/TopPoints'
import Opening from '../components/sections/home/Opening'
import Career from '../components/sections/home/career/Career'
import Credo from '../components/sections/home/Credo'
import NavButtons from '../components/navigation/NavButtons'


import ContentWrapper from '../components/design/ContentWrapper'
import Training from '../components/sections/home/Training'


const HomeScreen = () => {
    const pc = useMediaQuery()
    function is_retina_device() {
        return window.devicePixelRatio > 1;
    }

    // console.log(`is pixel = ${is_retina_device()} ratio if ${window.devicePixelRatio}`);
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <>
            <Hero />

            <ContentWrapper home>
                <div className="home">
                    <div className="home__buffer-top" />
                    <TopPoints />
                    <Opening />
                    <Career />
                    <Training />
                    <Credo />
                    {pc && <NavButtons bottom scrollTop={false} />}
                </div>
            </ContentWrapper>


        </>
    )
}

export default HomeScreen