export const CAREER_POINTS = [
    {
        catch: '給与アップが目標',
        txt: <p>前職の実績・<br />給与を参考に<br /><span className='cp__pnk'>優遇</span></p>,
    },
    {
        catch: '実力で稼ぎたい',
        txt: <p>業界<span className='cp__pnk'>最高</span><br />水準の<br /><span className='cp__pnk'>還元率</span></p>,
    },
    {
        catch: 'もっと学びたい',
        txt: <p>外部講習の<br />費用を<br /><span className='cp__pnk'>半額負担</span></p>,
    },
    {
        catch: 'プライベートも大切',
        txt: <p>全店<span className='cp__spr'>※</span><span className='cp__pnk'>18時</span><br />営業終了<br /><span className='cp__sm'>※梅田店を除く</span></p>,
    },
    {
        catch: '家庭の事情があって',
        txt: <p>正社員<br /><span className='cp__pnk'>時短勤務</span><br />優遇制度</p>,
    },

    {
        catch: 'ママやパパに',
        txt: <p>保育料<br /><span className='cp__pnk'>半額負担</span><br />制度</p>,
    },

]

export const CAREER_POINT_TXT = [
    '前職の実績・給与を参考に優遇', '業界最高水準の還元率', '外部講習の費用を半額負担', '全店※18時営業終了※梅田店を除く', '正社員時短勤務優遇制度', '保育料半額負担制度'
]

