import React from 'react'
import { IMG_PATH } from '../../constants/paths'

const SectionBkg = ({ img, color }) => {
    return (
        <div className="section-bkg">
            <img src={`${IMG_PATH}${img}`} className="section-bkg__top" />
            <div className="section-bkg__color" style={{ backgroundColor: color }} />
        </div>
    )
}

export default SectionBkg