import React from 'react'

import useGetLocation from '../../hooks/useSelectNav'
import usePageBottom from '../../hooks/usePageBottom'
import useScrollDirection from '../../hooks/useScrollDirection'
import NavButton from '../ui/buttons/NavButton'
import ScrollToTopButton from '../ui/buttons/ScrollToTopButton'


const NavButtons = ({ bottom, scrollTop = true }) => {
    const scDir = useScrollDirection()
    const btm = usePageBottom()
    const currentLcoation = useGetLocation()
    const guidelines = currentLcoation === 'guidelines'

    return (
        <>
            <nav className={`${scDir === 'down' || btm ? 'visible' : ''} ${bottom ? ' bottom' : ' main'}`}>
                <NavButton bottom={bottom} text={guidelines ? 'TOPへ戻る' : '募集要項'} url={guidelines ? '/' : '/guidelines'} />
                <NavButton bottom={bottom} text='応募フォーム' url='https://www.saraju.com/r-about/saraju-form/' />
            </nav>
            {scrollTop && <ScrollToTopButton className={scDir === 'down' || btm ? 'unjama' : null} />}
        </>
    )
}




export default NavButtons