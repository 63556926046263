import React from 'react'
import useMediaQuery from '../../../../hooks/useMediaQuery'
import SectionBkg from '../../../design/SectionBkg'
import FloatingDesc from '../../../design/FloatingDesc'
import ResponsiveImg from '../../../ui/ResponsiveImg'
import { IMG_PATH } from '../../../../constants/paths'
import { COLOR } from '../../../../constants/colors'
import { CAREER_POINTS, CAREER_POINT_TXT } from '../../../../data/careerPoints'

const CareerPoint = () => {
    const pc = useMediaQuery()
    const useImages = true;


    const header = (
        <div className="career-point__top">
            <ResponsiveImg src='photos/image-style' type='jpg' alt="careers" className="career-point__top-img" />


            <FloatingDesc txt='お客様の「なりたい」を叶えるあなた自身の「なりたい」も叶えるべきです。ここにはあなたの「なりたい」を一緒に叶える場所があり、仲間がいます。' catchTxt={`お客様を笑顔にした分、${pc ? `` : '\n'}自分が成長できる。${pc ? `` : '\n'}それが美容師という仕事。`} />

        </div>
    )
    return (
        <div className='career-point'>
            <SectionBkg color={COLOR.BLUE.color} img={`design/career-bg_${pc ? 'pc' : 'sp'}.svg`} />

            {/* //HEADER */}
            {!pc && header}

            {/* CONTENT */}
            <div className="section-content career-points">
                {pc && header}

                {/* TOP */}
                <div className="career-points__top">
                    <img className="career-points__good" src={IMG_PATH + 'typography/good.png'} alt="Good!" />
                    <ResponsiveImg className="career-points__catch" varRes={false} src={`typography/career-catch`} alt="いろんな「あなた」の「なりたい」を叶えるポイント！" />

                </div>

                {/* GRID */}
                <div className="career-points__point-grid">
                    {useImages ?
                        CAREER_POINT_TXT.map((a, i) => <img key={i} className='career-points__point-img' src={`${IMG_PATH}typography/cpoints-full/cpoint_${i + 1}.png`} alt={a} />) :
                        CAREER_POINTS.map((p, i) => <CirclePoint key={p.catch} alt={p.txt} txt={p.txt} img={`${IMG_PATH}typography/cpoints/cpoint_${i + 1}.png`} />)}
                </div>
            </div>
        </div>
    )

}

const CirclePoint = ({ img, alt, txt, key }) => {
    return (
        <div key={key} className="circle-point">
            <img src={img} alt={alt} />
            <div className="circle-point__circle">
                {txt}
            </div>
        </div>
    )
}

export default CareerPoint