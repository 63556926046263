import React from 'react'

import useMediaQuery from '../../../hooks/useMediaQuery'

import Testimonials from './Testimonials'
import FloatingDesc from '../../design/FloatingDesc'
import ResponsiveImg from '../../ui/ResponsiveImg'

import { IMG_PATH } from '../../../constants/paths'
import { TRAINING_TESTIMONIALS } from '../../../data/testimonials'
import { TRAINING_SCHEDULE_ITEMS, TRAINING_POINTS } from '../../../data/trainingData'

const Training = () => {
    const pc = useMediaQuery()
    return (
        <section className='training'>
            <div className="training__main">
                <TrainingTop />

                <div className="section-content">
                    <h3 className='img-header'><span>サラジュの教育プログラムは、</span>{!pc && <br />}<span className='img-header__flex-txt'><img src={IMG_PATH + 'typography/two-years.png'} className='training__txt-img' />での早期</span><span>スタイリストデビューが{!pc && <br />}可能です!</span></h3>

                    <div className="training__content">
                        {/* SCHEDULE */}
                        <div className="training__schedule">
                            {/* BUBBLE */}
                            <div className="training__schedule-bubble">
                                <img src={IMG_PATH + 'design/bubble-blank.svg'} className="training__schedule-bubble-img" />
                                <h4>2年間のスケジュール</h4>
                            </div>

                            <div className="training__schedule-items">
                                {TRAINING_SCHEDULE_ITEMS.map((item, i) => <TrainingScheduleItem key={item.title} item={item} />)}
                            </div>

                        </div>

                        {/* POINTS */}
                        <div className="training__point-section">
                            <ResponsiveImg src='photos/training-photo' type='jpg' alt="Training Points" />

                            <div className="training-points">
                                <div className="training-points__header">
                                    <img src={IMG_PATH + 'typography/good.png'} alt="Good!" />
                                    <h4>SARAJU 教育４つのポイント!</h4>
                                </div>
                                <div className="training-points__list">
                                    {TRAINING_POINTS.map((p, i) => <TrainingPoint key={p} num={i + 1} txt={p} divider={i < TRAINING_POINTS.length - 1} />)}
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Testimonials data={TRAINING_TESTIMONIALS} />
        </section>
    )
}

const TrainingTop = () => {
    const pc = useMediaQuery()
    return (
        <div className="training__top">
            <ResponsiveImg src='design/training-bkg' varRes={false} className="training__bkg-img" type='jpg' />
            <ResponsiveImg src='design/leaves/training-leaves' log varRes={false} type='svg' className="training__leaf" />
            <FloatingDesc txt={`でも、すぐに結果が出せる「あなた」、ちょっと時間がかかる「あなた」技術は得意でもコミュニケーションが苦手な「あなた」。\n「あなた」は様々です。\n人は完璧ではなく得意不得意、事情はみんな違うからこそ、${pc ? '\n' : ''}人から学べることがたくさんあり、チームワークによって${pc ? '\n' : ''}お客様に本当の「プロフェッショナル」を提供することができるのです。`} catchTxt={`お客様に対しては${!pc ? '\n' : ''}どんな立場の「あなた」も\n平等にプロフェッショナルで${!pc ? '\n' : ''}あることが求められます。`} />
        </div>
    )
}

const TrainingScheduleItem = ({ item }) => {
    const { period, title, points } = item;

    return (
        <div className="schedule-item">

            <div className="schedule-item__period">
                <img src={IMG_PATH + 'design/date-tag.svg'} />
                <p>{period}</p>
            </div>
            <div className="schedule-item__title">

                <h5>{title}</h5>
            </div>

            <ul className="schedule-item__points">
                {points.map(p => <li key={p}>{p}</li>)}
            </ul>
        </div>
    )
}

const TrainingPoint = ({ num, txt, divider }) => {
    return (
        <div className="training-point">
            <div className="training-point__content">
                <img src={IMG_PATH + `typography/point_numbers/${num}.png`} alt="" className="training-points__num" />
                <p>{txt}</p>
            </div>

            {divider && <img className='training-point__divider' src={IMG_PATH + 'design/divider-hor.svg'} />}
        </div>
    )
}



export default Training