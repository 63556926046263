import React from 'react'

const Footer = () => {
    return (
        <footer>
            <p>© saraju recruit. 2023. </p>
        </footer>
    )
}

export default Footer