export const CASES = [
  {
    title: '時短勤務、育児中のAさんの支給例',
    points: [
      <>
        毎週土日休み（年間休日数105日）
        <br />
        火曜日～金曜日9時～16時勤務
      </>,
      <>
        基本給 95000円＋技術手当120000円+勤続手当 18000円＋保育料半額負担分
        42000円－欠勤控除49600円－キャリア早退控除14248円
        <br />
        =211152円
      </>,
    ],
  },
  {
    title: '29歳、Bさんの支給例',
    points: [
      '指名人数: 175名／フリー人数: 9名',
      '客単価9861円',
      '年間休日数96日',
      <>
        基本給105000円＋技術手当120000円＋
        <br />
        勤続手当9000円＋歩合給289706円＝523706円
      </>,
    ],
    note: '役職給・各種手当は除く',
  },
];
