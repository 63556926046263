export const CAREER_TESTIMONIALS = [
  {
    position: 'スタイリスト',
    trajectory: 'キャリア採用',
    tenure: '勤続５年目',
    catchCopy:
      '仕事と家庭を両立しながら、長く働けるイメージが出来たのでSARAJUを選びました。',
    location: 'SARAJU 仁川店',
    name: '高山 美和子',
    content: `お客様に主婦層の方が多くいらっしゃる事もありプライベートなお話しが出来たりもするので、ただこなすだけの仕事をするのではなくお客様の綺麗のお手伝いをしながらも自分自身もすごく楽しませて頂けてとても充実した毎日を過ごすことが出来ています！\n若手スタッフも多いので日々新しい情報などを交換し合える事でとても良い刺激になり自分の成長に繋げることが出来たり、困った事があった時にはすぐに相談できる環境もあるので周りのスタッフにはとても感謝しています。\n家庭との両立やブランクがある事に不安を感じている方も多いと思いますが、SARAJUではスタッフみんなが心強いサポートもしてくれるので安心して働く事が出来ますし、何よりお客様の笑顔にたくさん出会える事でとても充実した毎日を過ごす事で出来ますのでぜひ一緒に美容師を楽しみましょう！！`,
    img: 'takayama',
  },
  {
    position: 'スタイリスト',
    trajectory: 'キャリア採用',
    tenure: '勤続４年目',
    catchCopy: '一人の人間として大きくなれる会社だと感じています。',
    location: 'SARAJU 三田店',
    name: '吉田 将崇',
    content: `サラジュを選んだ理由は、低価格を売りとするサロンが増えている一方で、サラジュでは技術力に見合った価格で勝負出来ると考えたからです。また、希望する働き方やライフスタイルに合わせて3種類の休日体制から選択することができ、プライベートも大切にできると考えました。\n縦（自店）のつながりだけでなく、横（他店）のつながりも大切にしており、技術はもちろん、接客や話し方、考え方等、今の自分に足りない部分を互いに高め合う事ができます。\nまた、サラジュのスタイリストとして1からスタートすることに、最初は不安もありました。しかし、お客様への入客チャンスが多くあり安心して取り組む事が出来ました。\n働いてからのことを不安に思う必要はありません。是非一緒に、サラジュで楽しく仕事をしていきましょう！`,
    img: 'yoshida',
  },
];

export const TRAINING_TESTIMONIALS = [
  {
    position: 'Jr.スタイリスト',
    trajectory: '新卒入社',
    tenure: '勤続３年目',
    catchCopy:
      '落ち込んだ時、悩んでる時に、手を差し伸べてくれる先輩方がいる環境を誇りに思っています!',
    location: 'SARAJU 西宮北口店',
    name: '村上 陽菜',
    content: `SARAJUでは、2年でスタイリストデビューをカリキュラムにしたサロンで、私は今もう少しで2年経つのですが、スタイリストに向けてあと一歩のところにきています！\nJrスタイリストに上がるまで、なかなかノルマ達成ができずステップアップができなかったのですが、店長や頼りになる先輩や、他店の先輩方に相談しアドバイスを頂き、今自分はJrスタイリストに昇格することが出来ました！！落ち込んだ時、悩んでる時に、手を差し伸べてくれる先輩方がいる環境を誇りに思っています。その誇りを自分が後輩に繋いでいけるようにしていきます！`,
    img: 'murakami',
  },
  {
    position: 'スタイリスト',
    trajectory: '新卒入社',
    tenure: '勤続11年目',
    catchCopy:
      'ワンランク上の技術、接客を学び、どこでも通用するレベルの高い美容師になれるようなお店づくりをしています。',
    location: 'SARAJU 西宮北口店',
    name: '店長　峯口 弘憲',
    content: `今ではどんなお客様を担当しても満足して頂ける自信があります。今は毎日お客様を笑顔にできて、店長としてスタッフたちの育成に100％力を注いで、それぞれが思う理想の美容師を実現させてあげれるように日々奮闘中です。スタッフが目標達成したときは、自分のことのように嬉しく感じます。せっかく美容師になるなら、自分の理想を叶えれるような働き方ができるようなサロンを選ぶことも大事なので、自分の目で確かめながらサロン選びをしてください！一緒に美容師を楽しみましょう!`,
    img: 'mineguchi',
  },
];
