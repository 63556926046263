import { Route, Routes, Navigate } from 'react-router-dom';


import Footer from './components/navigation/Footer';


import HomeScreen from './screens/HomeScreen';
import GuidelinesScreen from './screens/GuidelinesScreen';
import NavButtons from './components/navigation/NavButtons';


import { useLocation } from 'react-router-dom';
import { useState, useEffect } from 'react';



function App() {
  const location = useLocation()
  const [displayLocation, setDisplayLocation] = useState(location);
  const [transitionStage, setTransistionStage] = useState("fadeIn");


  useEffect(() => {
    if (location.pathname !== displayLocation.pathname) setTransistionStage("fadeOut");
  }, [location, displayLocation]);

  return (
    <>


      {/* <ScrollToTop /> */}
      <div className={transitionStage} onAnimationEnd={() => {
        if (transitionStage === "fadeOut") {
          setTransistionStage("fadeIn");
          setDisplayLocation(location);
        }
      }}>

        <Routes key='route-key' location={displayLocation}>
          <Route path='/' element={<HomeScreen />} key={'home'} />
          <Route path='/guidelines' element={<GuidelinesScreen />} key={'guidelines'} />
          <Route path="*" element={<Navigate to="/" replace />} key={'rehome'} />
        </Routes>

      </div>
      <Footer />
      <NavButtons />

    </>

  );
}

export default App;
