import React from 'react'
import useMediaQuery from '../../hooks/useMediaQuery'
import { IMG_PATH } from '../../constants/paths'

const ResponsiveImg = ({ src, type = 'png', className, alt, varRes = true, varImg = true, log }) => {

    const pc = useMediaQuery()

    const spImgBase = IMG_PATH + src + `${varImg ? '_sp' : ''}`
    const pcImgBase = IMG_PATH + src + `${varImg ? '_pc' : ''}`
    const lowRes = `${varRes && type !== 'svg' ? '--1x' : ''}`
    const lpc = `.${type}`



    return (
        // <img src={IMG_PATH + src + `_${pc ? 'pc' : 'sp'}.${type}`} className={className} alt={alt} />
        <picture className={className} alt={alt} style={{ display: 'inline-block' }} >
            <source srcSet={`${spImgBase}${lpc} 2x, ${spImgBase}${lowRes}${lpc} 1x`} src={`${spImgBase}${lpc}`} media="(max-width: 899px)" />
            <source srcSet={`${pcImgBase}${lpc} 2x, ${pcImgBase}${lowRes}${lpc} 1x`} src={`${pcImgBase}${lpc}`} media="(min-width: 900px)" />
            <source srcSet={`${pcImgBase}${lpc} 2x, ${pcImgBase}${lpc} 1x`} src={`${pcImgBase}${lpc}`} media="(min-width: 1441px)" />
            <img style={{ width: '100%' }} src={`${pc ? pcImgBase : spImgBase}${lpc}`} alt={alt} />
        </picture>
    )
}

// srcSet = {`${IMG_PATH}logo.png 2x, ${IMG_PATH}logo-sm.png 1x`} src = { IMG_PATH + 'logo.png'}

export default ResponsiveImg