import React from 'react'
import { IMG_PATH } from '../../constants/paths'

const LocationCard = ({ location }) => {
    const { name, imgName } = location
    return (
        <div className="loc-card">
            <img src={`${IMG_PATH}location/${imgName}.jpg`} alt={name} className="loc-card__img" />
            <p className="loc-card__label">{name + '店'}</p>
        </div>
    )
}

export default LocationCard