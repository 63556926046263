import React from 'react'

const ApplicationProcess = () => {
    return (
        <section className='application-process'>
            <div className="application-process__content">
                <h2>応募後の流れ</h2>
                <div className="application-process__main-txt">
                    <p>下記応募フォームにて基本情報をご記入してエントリーしてください。<br />↓<br />電話、メール、いずれかにて折り返しご連絡いたします。<br />*ドメイン指定受信の解除を予めして下さい。<br />ご連絡の際に勤務条件ご希望などのヒアリングをさせていただきます。<br />↓<br />担当者と面接<br />↓<br />採用決定<br />↓<br />入社</p>
                </div>
                {/* <p className="application-process__note">*採用方法が変更となる場合もございますので、ご了承ください。</p> */}
            </div>
        </section>
    )
}

export default ApplicationProcess

