export const POSITION_DETAILS = {
  stylist: {
    id: 'stylist',
    name: 'スタイリスト',
    details: [
      {
        name: '募集役職',
        desc: <p>スタイリスト美容師<br />正社員、パートさんどちらも大歓迎!<br />（ご家庭をお持ちの方、子育て中の美容師さんは時短勤務も可能です、時短勤務優遇制度、保育料半額負担制度あり）</p>
      },
      {
        name: '給　与',
        desc: (<>
          <p>【正社員A】<br />月給 25.0万円〜80.0万円（前職の実績・給与を参考に優遇させて頂きます）<br />☆指名技術売上 30％ フリー技術売上 25％  還元<br />＜トップスタイリスト ＞<br />30万円保証＋社会保険完備<br />＜ スタイリスト＞<br /> 25万円保証＋社会保険完備</p >
          <p>【正社員B】<br />完全歩合給制（アシスタントなしのマン ツ ーマンワーク）<br />☆技術売上 指名45％ フリー40％ 還元、補償給制度あり</p>
          <p>【アルバイト・パート】<br />時給 1,400円〜1,600円 (実績・経験により優遇）</p><p> 【業務委託】<br /> 完全歩合給制<br />☆技術売上 指名50％ フリー45％ 還元、補償給制度あり</p>
        </>)
      },
      {
        name: '仕事内容',
        desc: <p>ヘアサロン内での業務全般</p>
      },
      {
        name: '勤務地',
        desc: <p>全店舗にて募集中</p>
      },
      {
        name: '勤務時間',
        desc: (
          <>
            <p>【正社員A】<br />
              {/* ＜西宮ガーデンズ店＞<br />10:00〜20:00<br />10:00~20:00の間でシフト制（時間単位のフレックス制を導入）<br />早番10:00~19:00 、遅番11:00~20:00<br />※木曜日はレッスン日の為：営業が18:00まででレッスンが20:00までとなります。 */}
              ＜梅田店＞<br />火・水・木曜日11:00～20:00 <br />金曜日 12:00～21:00 <br />土曜・日曜日 10:00～19:00<br />※木曜日はレッスン日の為：営業が18:00まででレッスンが20:00までとなります。</p>
            <p>＜その他店舗＞<br />09:00〜18:00<br />※木曜日はレッスン日の為：営業が16:00まででレッスンが18:00までとなります。</p>
            <p>【アルバイト・パート】<br />09:00〜18:00<br />10:00〜20:00<br />上記営業時間内で応相談（週2日～勤務OK）</p>
            <p>【正社員B・業務委託】<br />完全自由出勤</p>
          </>
        )
      },
      {
        name: '休　日',
        desc: <p>【正社員A】<br />選べる！年間休日選択制<br /> 85日（月休７日制）、 96日（月休8日制）、 105日（完全週休２日制）</p>
      },
      {
        name: '必要資格・経験',
        desc: <p>美容師免許<br />スタイリスト / ジュニアスタイリスト経験</p>
      },
      {
        name: '福利厚生',
        desc: (
          <>
            <div>
              <p>【正社員A】</p>
              <ul>
                <li>社会保険完備</li>
                <li>子育て中美容師支援(保育料半額負担)</li>
                <li>時短勤務者支援制度</li>
                <li>スキルアップ支援(外部講習費用半額負担)</li>
                <li>勤続手当</li>
                <li>独立支援制度</li>
              </ul>
            </div>
            <div>
              <p>【正社員B】</p>
              <ul>
                <li>社会保険完備</li>
              </ul>
            </div>
          </>
        )
      },
      {
        name: '交通費',
        desc: <p>【正社員A・アルバイト・パート】<br />交通費支給（15,000円まで）</p>
      },
      {
        name: 'その他特徴',
        desc: <p>経験者歓迎、駅近、車・バイク通勤OK、地域密着</p>
      },
    ],
    foot: (
      <>
        <div>
          <h3>こだわり・自慢！</h3>
          <p>当社ではスタッフそれぞれのライフスタイルにあわせて勤務して頂けるよう3パターンの休日数選択制度を採用致しております。お休みは少なくても良いのでバリバリ稼ぎたい人、お休みをしっかりとってプライベートを充実させたい人、どんな人もウエルカムです！</p>
        </div>
        <div>
          <p>☆アシスタントにアシストして貰いながらも高待遇 指名技術売上 30％ フリー技術売上 25％ 還元</p>
        </div>
        <div>
          <p>☆当社の営業時間は9：00～18：00（実働8時間）、18時営業完全終了（一部店舗を除く）なので営業終わりのプライベートタイムも充実しています！</p>
        </div>
        <div>
          <p>☆ママさん美容師さんに嬉しい2大応援制度 ！</p>
          <ul>
            <li>保育料半額負担</li>
            <li>時短勤務支援制度（時短勤務によりお給料が下がり過ぎないように基本給のみ対象に減給する制度）</li>
          </ul>
        </div>
      </>
    )

  },

  assistant: {
    id: 'assistant',
    name: '新卒生・アシスタント',
    nameSP: <>新卒生・<br />アシスタント募集</>,
    details: [
      {
        name: '募集役職',
        desc: <p>正社員、アシスタント美容師<br />新卒生、第２新卒生大歓迎！<br />経験者はキャリアを考慮の上優遇いたします。</p>
      },
      {
        name: '給　与',
        desc: (<>
          <p>月給 20.0万円〜22.0万円<br />基本給+技術手当+歩合給<br />＊過去のキャリアと当社規定により決定致します<br />＊実績・経験により優遇いたします</p>
        </>)
      },
      {
        name: '仕事内容',
        desc: <p>ヘアサロン内での業務全般</p>
      },
      {
        name: '勤務地',
        desc: <p>全店舗にて募集中</p>
      },
      {
        name: '勤務時間',
        desc: (
          <>
            <p>【正社員】<br />
              {/* ＜西宮ガーデンズ店＞<br />10:00〜20:00<br />10:00~20:00の間でシフト制（時間単位のフレックス制を導入）<br />早番10:00~19:00 、遅番11:00~20:00<br />※木曜日はレッスン日の為：営業が18:00まででレッスンが20:00までとなります。 */}
              ＜梅田店＞<br />火・水・木曜日11:00～20:00<br />金曜日 12:00～21:00<br />土曜・日曜日 10:00～19:00<br />※木曜日はレッスン日の為：営業が18:00まででレッスンが20:00までとなります。</p>
            <p>＜その他店舗＞<br />09:00〜18:00<br />※木曜日はレッスン日の為：営業が16:00まででレッスンが18:00までとなります。</p>
          </>
        )
      },
      {
        name: '休　日',
        desc: <p>【正社員】<br />選べる！年間休日選択制<br /> 85日（月休７日制）、 96日（月休8日制）、 105日（完全週休２日制）</p>
      },
      {
        name: '必要資格・経験',
        desc: <p>美容師免許<br />アシスタント / ジュニアスタイリスト経験</p>
      },
      {
        name: '福利厚生',
        desc: (
          <>
            <ul>
              <li>社会保険完備 </li>
              <li>子育て中美容師支援(保育料半額負担)</li>
              <li>時短勤務者支援制度</li>
              <li>スキルアップ支援(外部講習費用半額負担)</li>
              <li>残業手当</li>
              <li>勤続手当</li>
              <li>独立支援制度</li>
            </ul>
          </>
        )
      },
      {
        name: '交通費',
        desc: <p>交通費支給（15,000円まで） </p>
      },
      {
        name: 'その他特徴',
        desc: <p>経験者歓迎、駅近、車・バイク通勤OK、地域密着</p>
      },
    ],
    foot: (
      <>

        <div>
          <h3>こだわり・自慢！</h3>
          <p>❛愛❜・全てのゲストに感謝し、愛をもって対応します・社員全員に愛をもってやり甲斐、働き甲斐のある職場作りと夢の実現に尽くします・一人の為に皆が、皆の為に一人が愛をもって行動します</p>
        </div>

      </>
    )
  }




}



