import React from 'react'
import { IMG_PATH } from '../../../constants/paths'
import ResponsiveImg from '../../ui/ResponsiveImg'
const Testimonials = ({ data }) => {

    return (
        <div className='section-content testimonials'>
            {data.map((t, i) => <Testimonial key={t.name} data={t} border={i < data.length - 1} />)}
        </div>
    )
}

const Testimonial = ({ data, border }) => {
    const { position, trajectory, tenure, catchCopy, location, name, content, img } = data
    const tabs = [position, trajectory, tenure]
    
    return (
        <div className={`testimonial${border ? ' border' : ''}`}>
            <div className="testimonial__header">
                <div className="testimonial__tabs">
                    {tabs.map(t => <TestimonialTab key={t} txt={t} />)}
                </div>
                <ResponsiveImg type='jpg' src={`photos/testimonials/${img}`} varImg={false} alt={name} className="testimonial__img" />
            </div>
            <div className="testimonial__content">
                <div className="testimonial__top-txt">
                    <p className="testimonial__catch">
                        {catchCopy}
                    </p>
                    <div className="testimonial__byline">
                        <p>{location}</p>
                        <p>{name}</p>
                    </div>
                </div>
                <p className="testimonial__main-txt">
                    {content}
                </p>
            </div>
        </div>
    )
}

const TestimonialTab = ({ txt }) => {
    return (
        <div className="testimonial__tab">
            <span>{txt}</span>
        </div>
    )
}





export default Testimonials