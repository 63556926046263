export const COLOR = {
  BKG_LT: {
    name: 'light background',
    color: '#F5F5F5',
  },
  BLACK: {
    name: 'black',
    color: '#000000',
  },
  BLACK_02: {
    name: 'black 02',
    color: '#040000',
  },
  BLUE: {
    name: 'blue',
    color: '#9AC5E5',
  },
  BLUE_LT: {
    name: 'light blue',
    color: '#CDDFED',
  },
  BROWN: {
    name: 'brown',
    color: '#463228',
  },
  DUST: {
    name: 'dust',
    color: '#EBF0EC',
  },
  FRUITY: {
    name: 'tutti frutti',
    color: '#E36255',
  },
  GREY: {
    name: 'grey',
    color: '#C8C8C8',
  },
  PEACH: {
    name: 'peach',
    color: '#F3E5D0',
  },
  PINK: {
    name: 'pink',
    color: '#C98C9A',
  },
  PINK_BKG: {
    name: 'pink bkg',
    color: '#E5C6C3',
  },
  TAN: {
    name: 'tan',
    color: '#EDCE7A',
  },
  TURQUOISE: {
    name: 'turquoise',
    color: '#4FB19D',
  },
  WHITE: {
    name: 'white',
    color: '#FFFFFF',
  },
};

export const COLORS = [
  COLOR.BKG_LT,
  COLOR.BLACK,
  COLOR.BLACK_02,
  COLOR.BLUE,
  COLOR.BLUE_LT,
  COLOR.BROWN,
  COLOR.DUST,
  COLOR.FRUITY,
  COLOR.GREY,
  COLOR.PEACH,
  COLOR.PINK,
  COLOR.PINK_BKG,
  COLOR.TAN,
  COLOR.TURQUOISE,
  COLOR.WHITE,
];
